import { Input } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "./opalite_colors";

interface OpaliteInputProps {
  placeholder?: string;
  setText: Function;
}

export const OpaliteInput: React.FC<OpaliteInputProps> = (props) => {
  return (
    <Input
      color={COLORS_PRIMARY.FONT}
      onChange={(e) => props.setText(e.target.value)}
      placeholder={props.placeholder}
      {...props}
    />
  );
};
