import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import Login from "../components/login/login";

function LoginPage() {
  return (
    <ChakraProvider>
      <Header />
      <Login />
    </ChakraProvider>
  );
}

export default LoginPage;
